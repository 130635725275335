// @flow
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  Headline,
  ImprovementsCard,
  TabNavigation,
  TreeCard,
  MoneybagCard,
} from '../../components';

// import EmbedNoEmPage from './embed-no-em';
import EmbedEmNoResultsPage from './embed-em-no-results';
import { getLocationDetailsByCaseNumber } from '../../utils/api';

const styles = () => ({
  content: {
    width: 'auto',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundColor: '#eee',
    padding: '40px 0px 40px 0px',
  },
  headerSubtitle: {
    marginTop: 17,
    maxWidth: '50%',
    marginRight: '10px',
    '@media (max-width: 640px)': {
      maxWidth: '90%',
    },
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '5px',
  },
  closeButton: {
    alignSelf: 'flex-end',
    margin: '10px',
  },
  dialogRoot: {
    '& .MuiDialog-scrollPaper': {
      position: 'absolute',
      right: 100,
      top: 60,
      backgroundColor: 'rgba(0,0,0,0)',
      '@media (max-width: 640px)': {
        right: 0,
        top: 100,
      },
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiDialog-container': {
      height: 'auto',
    },
    '& .MuiDialog-paper': {
      backgroundColor: 'white',
      borderRadius: '10px',
      width: '90%',
    },
  },
});

type Props = {
  classes: any,
  propertyId: string,
};

const improvementCardData = (data, currentTab: number) => {
  const result = {
    title: '',
    description: '',
    profitable: '',
    greenImprovementValue: 0,
  };
  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].ShortText) {
      result.title = data.profilt[currentTab].ShortText;
    }

    if (data.profilt[currentTab] && data.profilt[currentTab].profitable) {
      result.profitable = data.profilt[currentTab].profitable;
    }

    if (data.profilt[currentTab] && data.profilt[currentTab].LongText) {
      result.description = data.profilt[currentTab].LongText;
    }

    if (
      data.profilt[currentTab] &&
      data.profilt[currentTab]._greenImprovementValue
    ) {
      result.greenImprovementValue =
        data.profilt[currentTab]._greenImprovementValue;
    }
  }
  return result;
};

const treeCardData = (data, currentTab: number) => {
  const result = {
    CO2Saving: '',
  };

  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].CO2Saving) {
      result.CO2Saving = parseFloat(data.profilt[currentTab].CO2Saving).toFixed(
        2
      );
    }
  }
  return result;
};

const moneyBagData = (data, currentTab: number) => {
  const result = {
    MoneySaving: '',
    Investment: '',
  };

  if (data && data.profilt) {
    if (data.profilt[currentTab] && data.profilt[currentTab].MoneySaving) {
      result.MoneySaving = parseInt(data.profilt[currentTab].MoneySaving, 10);
    }

    if (data.profilt[currentTab] && data.profilt[currentTab].Investment) {
      result.Investment = parseInt(data.profilt[currentTab].Investment, 10);
    }
  }
  return result;
};

const isAllImprovementTab = (data, currentTab: number) => {
  let result = false;
  if (
    data &&
    data.profilt &&
    data.profilt[currentTab] &&
    data.profilt[currentTab]._isAllTab === true
  ) {
    result = true;
  }
  return result;
};

const isAllTabProfitable = (data, currentTab: number) => {
  let result = false;
  if (
    data &&
    data.profilt &&
    data.profilt[currentTab] &&
    data.profilt[currentTab].profitable === 'true'
  ) {
    result = true;
  }
  return result;
};

function EmbedPage(props: Props) {
  const { classes, propertyId } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [currentTab, setCurrentTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    getLocationDetailsByCaseNumber(propertyId)
      .then(result => {
        setLoading(false);
        setData(result);
      })
      .catch(() => {
        setData({});
      });
  }, [propertyId]);

  const onHelpIconClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const {
    title,
    description,
    profitable,
    greenImprovementValue,
  } = improvementCardData(data, currentTab);
  const { CO2Saving } = treeCardData(data, currentTab);
  const { MoneySaving, Investment } = moneyBagData(data, currentTab);
  const allImprovements = isAllImprovementTab(data, currentTab);
  const allTabProfitable = isAllTabProfitable(data, currentTab);

  if (!data || loading) {
    return null;
  }

  if (data && data.profilt && data.profilt.length > 0) {
    return (
      <div className={classes.content}>
        <Grid
          key={propertyId}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid key="header" item xs={12}>
            <Box>
              <Headline />
              <div className={classes.headerText}>
                <Typography
                  variant="subtitle1"
                  className={classes.headerSubtitle}
                >
                  Hvad betyder energiforbedringer af boligen for klimaet og for
                  din økonomi? Bliv klogere med klimaberegneren – som straks
                  viser dig gevinsten ved de forbedringer, du ønsker.
                </Typography>
                <CustomButton onClick={onHelpIconClick}>
                  <HelpOutlineIcon
                    fontSize="small"
                    style={{ color: '#979797' }}
                  />
                </CustomButton>
              </div>
            </Box>
          </Grid>
          <Grid key="tab" item xs={12}>
            <Box margin="0px">
              <TabNavigation
                currentTab={currentTab}
                data={data}
                handleTabChange={(ev, value) => {
                  ReactGA.event({
                    category: 'Navigation',
                    action: 'Tab Change',
                    label: data.title,
                    value: data.id,
                  });
                  setCurrentTab(value);
                }}
              />
            </Box>
          </Grid>
          <Grid
            container
            key="cards"
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item key="improvement-card" xs={12} lg={4}>
              <ImprovementsCard
                key={currentTab}
                title={title}
                profitable={profitable}
                description={description}
                greenImprovementValue={greenImprovementValue}
              />
            </Grid>
            <Grid item key="saved-trees" xs={12} lg={4}>
              <TreeCard
                allTabProfitable={allTabProfitable}
                allImprovements={allImprovements}
                key={currentTab}
                CO2Saving={CO2Saving}
              />
            </Grid>
            <Grid item key="money-bag" xs={12} lg={4}>
              <MoneybagCard
                key={currentTab}
                profitable={profitable}
                allTabProfitable={allTabProfitable}
                MoneySaving={MoneySaving}
                Investment={Investment}
                allImprovements={allImprovements}
              />
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          onClose={handleClose}
          classes={{ root: classes.dialogRoot }}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className={classes.dialogContent}>
            <Typography paragraph>
              Min Klimaberegner er din guide til en mere klimavenlig bolig. Du
              indtaster en adresse, hvorefter Min Klimaberegner henter data fra
              boligens energimærke. Du vælger selv, hvilke (og hvor mange)
              forbedringsforslag du ønsker at gennemføre for boligen, og
              gevinsten ved forbedringen vises i beregneren.
            </Typography>
            <Typography paragraph>
              Energimærket er lovpligtigt i forbindelse med salg af bolig i
              Danmark. Det er Energistyrelsen, der har ansvaret for
              energimærkningsordningen.
            </Typography>
            <Typography paragraph>
              Klimaberegneren viser ikke kun, hvad forbedringen koster for dig,
              og hvor stor din årlige energibesparelse er. Den viser også, hvad
              forbedringen betyder for klimaet. Det visualiseres ved at omregne
              din CO2-nedbringelse til antallet af træer, som årligt kan optage
              den samme mængde CO2.
            </Typography>
            <Typography paragraph>
              En beregningsmodel, der er udviklet i samarbejde med Palle Madsen,
              som er PhD. i skovdyrkning samt seniorforsker og ejer af
              InNovaSilva ApS. Han er desuden forfatter til Klimaskoven, der
              handler om skovens store betydning for klimaet.
            </Typography>
            <Typography paragraph>
              Min Klimaberegner er udviklet af HUSSYN.PRO.
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  if (data && data.energyMarkLabel) {
    return <EmbedEmNoResultsPage propertyData={data} />;
  }

  return null;
}

const CustomButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
`;

export default withStyles(styles)(EmbedPage);
