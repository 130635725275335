// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import { Tab, Tabs } from '@material-ui/core';

type Props = {
  currentTab: string,
  data: any,
  handleTabChange: Function,
};

function TabNavigation(props: Props) {
  const { currentTab, data, handleTabChange } = props;

  if (data && data.profilt) {
    return (
      <TabsNavigationContainer>
        <StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textcolor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable tabs"
        >
          {data.profilt.map(tabs => (
            <StyledTab
              isProfitable={tabs.profitable === 'true'}
              key={tabs.id}
              label={tabs.title || '(no title)'}
            />
          ))}
        </StyledTabs>
      </TabsNavigationContainer>
    );
  }

  return null;
}
const TabsNavigationContainer = styled.div`
  background-color: #eee;
  padding: 26px 0 0 0;
`;

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
  scrollButtonsDesktop: {
    display: 'inline-flex',
  },
  scrollable: {
    '-ms-overflow-style': 'none',
  },
  root: {
    alignItems: 'center',
  },
})(props => <Tabs {...props} />);

const StyledTab = withStyles({
  root: {
    flex: '0',
    borderRadius: 15,
    padding: '0px 15px',
    fontSize: '15px',
    margin: '0',
    '&:first-child': {
      margin: '0 12.5px 0 0px',
    },
    '&$selected': {
      color: '#003761',
    },
    // maxWidth: 'max-content',
    minWidth: 'max-content',
    minHeight: '27px',
    textTransform: 'capitalize',
  },
  wrapper: {
    width: 'auto',
  },
  textColorPrimary: {
    color: '#616266',
    fontSize: '19px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '18px',
    padding: '0',
    textTransform: 'capitalize',
  },
  selected: {
    backgroundColor: 'rgba(0, 55, 97, 0.1)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '16px',
    textTransform: 'capitalize',
  },
})(props => {
  const { isProfitable, ...otherProps } = props;
  return (
    <Tab style={{ opacity: isProfitable ? 1 : 0.4 }} disableRipple {...otherProps} />
  );
});

export default TabNavigation;
