// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';
import { Typography, Box } from '@material-ui/core';
import Tree from '../tree/tree';

type Props = {
  treesData: any,
  forwardedRef?: any,
  inViewport?: boolean,
  enterCount?: number,
  playImmediately?: boolean,
  hideCounter?: boolean,
};

const treesCombinations = {
  '7': {
    tree4: {
      bottom: '0',
      left: '0',
      zIndex: '1',
      maxWidth: '18.35%',
    },
    tree3Dark: {
      bottom: '14.29%',
      left: '6.3%',
      zIndex: '0',
      maxWidth: '29.05%',
    },
    tree1: {
      left: '20%',
      bottom: '5.88%',
      zIndex: '1',
      maxWidth: '23.01%',
    },
    tree2: {
      left: '36%',
      bottom: '0',
      zIndex: '2',
      maxWidth: '13.97%',
    },
    tree5Dark: {
      left: '43.3%',
      bottom: '27.74%',
      zIndex: '0',
      maxWidth: '19.89%',
    },
    tree5: {
      left: '51.16%',
      bottom: '0',
      zIndex: '1',
      maxWidth: '19.89%',
    },
    tree3: {
      left: '60.4%',
      bottom: '0',
      zIndex: '2',
      maxWidth: '29%',
    },
    tree2Dark: {
      left: '73.3%',
      bottom: '11%',
      zIndex: '0',
      maxWidth: '13.97%',
    },
    tree4Dark: {
      left: '82.2%',
      bottom: '4.25%',
      zIndex: '1',
      maxWidth: '18.35%',
    },
  },
  '6': {
    tree4: {
      left: '2%',
      bottom: '0',
      zIndex: '1',
      maxWidth: '18.35%',
    },
    tree1Dark: {
      left: '15%',
      bottom: '5.98%',
      zIndex: '0',
      maxWidth: '25.48%',
    },
    tree2: {
      left: '34.22%',
      bottom: '0',
      zIndex: '1',
      maxWidth: '13.97%',
    },
    tree5Dark: {
      left: '46.9%',
      bottom: '7.06%',
      zIndex: '0',
      maxWidth: '19.89%',
    },
    tree3: {
      left: '59.44%',
      bottom: '0',
      zIndex: '1',
      maxWidth: '29%',
    },
    tree4Dark: {
      left: '80.77%',
      bottom: '7.06%',
      zIndex: '0',
      maxWidth: '18.35%',
    },
  },
  '5': {
    tree1: {
      left: '3%',
      bottom: '0',
      maxWidth: '23.01%',
    },
    tree2: {
      left: '25.33%',
      bottom: '0',
      maxWidth: '13.97%',
      zIndex: '1',
    },
    tree3: {
      left: '35.54%',
      bottom: '5.24%',
      maxWidth: '29%',
    },
    tree4: {
      left: '61.56%',
      bottom: '0',
      maxWidth: '18.35%',
    },
    tree5: {
      left: '75.92%',
      bottom: '5px',
      maxWidth: '19.89%',
    },
  },
  '4': {
    tree5: {
      left: '10%',
      bottom: '4.23%',
      maxWidth: '19.89%',
    },
    tree4: {
      left: '29.4%',
      bottom: '0',
      maxWidth: '18.35%',
    },
    tree1: {
      left: '48%',
      bottom: '0',
      maxWidth: '23.01%',
    },
    tree2: {
      left: '71.84%',
      bottom: '0',
      maxWidth: '13.97%',
    },
  },
  '3': {
    tree4: {
      left: '17%',
      bottom: '0px',
      maxWidth: '18.35%',
    },
    tree5: {
      left: '39.85%',
      bottom: '10px',
      maxWidth: '19.89%',
    },
    tree3: {
      left: '58.70%',
      bottom: '5px',
      maxWidth: '29%',
    },
  },
  '2': {
    tree5: {
      left: '29%',
      bottom: '5px',
      maxWidth: '19.89%',
    },
    tree4: {
      left: '55.64%',
      bottom: '0',
      maxWidth: '18.35%',
    },
  },
  '1': {
    tree1: {
      left: '41%',
      bottom: '0',
      maxWidth: '23.01%',
    },
  },
};

export default function Trees(props: Props) {
  const {
    treesData,
    inViewport,
    enterCount,
    playImmediately,
    forwardedRef,
    hideCounter,
  } = props;

  const { countUp, start } = useCountUp({
    start: 0,
    end: treesData.noOfTrees,
    delay: 0,
    duration: 1.5,
    separator: '.',
    suffix: ' træer',
    decimals: 0,
    preserveValue: true,
  });

  useEffect(() => {
    if (!inViewport && enterCount === 0 && countUp) {
      start();
    }
  }, [enterCount, inViewport, start, countUp]);

  return (
    <Container ref={forwardedRef}>
      <TreesContainer className="trees-container">
        <TreesFrontlineContainer>
          {Object.keys(treesCombinations[treesData.treesAnimation]).map(
            tree => (
              <TreeContainer
                key={`frontline-${tree}`}
                style={treesCombinations[treesData.treesAnimation][tree]}
              >
                <Tree
                  delay={Math.floor(Math.random() * 500)}
                  treeVariant={tree}
                  play={!inViewport && enterCount === 0}
                  playImmediately={playImmediately}
                />
              </TreeContainer>
            )
          )}
        </TreesFrontlineContainer>
      </TreesContainer>

      {!hideCounter && (
        <Box color="#202124" fontSize="30px" fontWeight={600}>
          <Box
            color="#003764"
            fontSize="28px"
            display="inline-block"
            fontWeight={600}
          >
            <span>{countUp}</span>
          </Box>
        </Box>
      )}
    </Container>
  );
}

Trees.defaultProps = {
  forwardedRef: null,
  inViewport: true,
  playImmediately: false,
  enterCount: 0,
  hideCounter: false,
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  // height: 100%;
  // margin-bottom: 10px;
  max-width: 315px;
  position: relative;
  width: 100%;
  // border: 1px solid red;
  // margin: auto;
`;

const TreesContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  // justify-content: center;
  min-height: 119px;
  margin: 80px 0 45px 0;
  position: relative;
  width: 100%;
`;

const TreesFrontlineContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: inherit;
  width: 100%;
  z-index: 1;
`;

const TreeContainer = styled.div`
  position: absolute;
  max-width: 100px;
  width: 100%;
`;
