// @flow
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import 'typeface-roboto';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
// eslint-disable-next-line
import MyZoidComponent from './widget/widget';
import App from './App';
import { gaCode } from './config';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 846,
      xl: 1200,
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 846,
      xl: 1200,
    },
  },
  palette: {
    background: {
      paper: '#eee',
      default: '#eee',
    },
  },
  tabNavigation: {
    backgroundColor: '#eee',
  },
  typography: {
    fontFamily: 'RealBook, Helvetica, Arial, sans-serif',
    h1: {
      color: '#003764',
      fontWeight: 500,
      fontSize: '30px',
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '40px',
      },
    },
    h2: {
      color: '#003764',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '30px',
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
    },
    subtitle1: {
      color: '#003764',
      fontSize: '15px',
      lineHeight: '20px',
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    subtitle2: {
      color: '#7B7D82',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: '1px',
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '15px',
        lineHeight: '16px',
      },
    },
    body1: {
      color: '#7B7D82',
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    link: {
      color: '#13A970',
    },
  },
});

ReactGA.initialize('UA-103640126-4');

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    rootElement
  );
}
